import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { EnterpriseCoreModule, LicenseManager } from '@ag-grid-enterprise/core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import '@draft-js-plugins/mention/lib/plugin.css';
import 'antlr4';
import 'draft-js/dist/Draft.css';
import 'react-virtualized/styles.css';
import { reactBaseUrl } from '~/data/env';
import '~n/lib/i18n/i18n';
import './ds';
import './styles';
import './ui-kit/atoms/Icon/icons'; // preload svg files for svg-sprite-loader
if (process.env.NODE_ENV !== 'test') {
    __webpack_public_path__ = `${reactBaseUrl}/`;
}
ModuleRegistry.registerModules([
    EnterpriseCoreModule,
    ClipboardModule,
    ClientSideRowModelModule,
    MenuModule,
    SideBarModule,
    MasterDetailModule,
    RowGroupingModule,
    ColumnsToolPanelModule,
    ServerSideRowModelModule,
]);
LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-052133}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Fohlio_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Fohlio_Front_End}_only_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Fohlio_Front_End}_need_to_be_licensed___{Fohlio_Front_End}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{18_February_2025}____[v3]_[01]_MTczOTgzNjgwMDAwMA==f8b3a551c980877b60178cd2d697c2f0');
